<template>
  <div>
    <div class="content">
      <div class="isActive">
        <MySwitch :label="t('isActive')"/>
      </div>
      <div class="title">
        <input type="text" class="form-control" placeholder="Başlık">
      </div>
      <div class="description">
        <QuillEditor scrollingContainer="true"/>
      </div>
    </div>
    <div class="btnSet right">
      <button class="btn btn-outline-danger">Vazgeç</button>
      <button class="btn btn-primary">Kaydet</button>
    </div>
  </div>
</template>

<script>
import MySwitch from '../../components/MySwitch'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import useGlobal from '../../composables/useGlobal'

export default {
  name: 'Contact',
  components: {
    MySwitch,
    QuillEditor
  },
  setup() {
    const { t } = useGlobal()


    return {
      t
    }

  }


}
</script>

<style scoped>
.content {
  display: grid;
  gap: 1em;
}

.description {
  position: relative;
  height: max-content;
  margin-bottom: 1em;
  min-height: 200px;
}
</style>
